//@ts-check
import '../../styles/B2CUserTOUForm.css';

import React, { useState } from 'react';
import env from 'react-dotenv';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../auth/authConfig';
import { Form, FormGroup, Label, Input, Alert } from "reactstrap"; 
// import Card from 'react-bootstrap/Card'
import { Card, Spinner, Button, Table } from "react-bootstrap"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

function jwtDecode(token) {
    const [header, payload, signature] = token.split(".");
    const decodedPayload = JSON.parse(new TextDecoder().decode(Uint8Array.from(atob(payload), c => c.charCodeAt(0))));
    return decodedPayload;
}

export const B2CUserTOUForm = () => {
    const [userSearch, setUserSearch] = useState("");
    // const [touInfo, setTouInfo] = useState(/** @type {null | Array} **/(null));
    const [touInfo, setTouInfo] = useState(/** @type {null | object} **/(null));
    const [loading, setLoading] = useState(false);
    const [locked, setLocked] = useState(false);
    const [canAddTOURequirement, setCanAddTOURequirement] = useState(false);
    const [canRemoveTOURequirement, setCanRemoveTOURequirement] = useState(false);
    const [canResetTOUStatus, setCanResetTOUStatus] = useState(false);
    const [message, setMessage] = useState(/** @type {null | {text: string, type: string}} **/(null));
    const { instance, accounts } = useMsal();
    
    const handleSubmit = async (event) => {
        setLoading(true);
        try {
            event.preventDefault();
            console.log(`Searching for B2C User: ${userSearch}`);

            const tokenResponse = await instance.acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
            });

            const decodedAccessToken = jwtDecode(tokenResponse.accessToken);

            let response;
            try {
                var touURI = env.API_B2C_USERS_ENDPOINT+"/"+userSearch+"/tou?code=" + env.API_B2C_USERS_CODE;

                response = await fetch(touURI, {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${tokenResponse.accessToken}`,
                        "Content-Type": "application/json",
                    }
                });

            } catch (e) {
                console.error(e);
                // stack trace (string): e.stack
                // string message: e.message
                setMessage({ type: 'danger', text: 'Error communicating with server' });
                return;
            }

            if (response.status === 200) {
                console.log("Success");
                let jsonString = await response.text();
                let jsonObject = JSON.parse(jsonString);
                let touSummary = jsonObject.summary;

                console.log(jsonObject);

                if (touSummary != undefined) {
                    setTouInfo(touSummary);
                    setLocked(true);
                    setMessage(null);

                    if(touSummary.TOURequired){
                        setCanRemoveTOURequirement(true);
                        setCanAddTOURequirement(false);
                    } else {
                        setCanRemoveTOURequirement(false);
                        setCanAddTOURequirement(true);
                    }

                    if(touSummary.TOUAccepted){
                        setCanResetTOUStatus(true);
                    }
                } else {
                    setTouInfo(null);
                    setMessage({
                        text: 'Response from API did not return summary object.',
                        type: 'danger',
                    });
                }

            } else if (response.status === 400) {
                console.log("Bad Request");
                let badRequestMessage = await response.text();
                setTouInfo(null);
                setMessage({
                    text: badRequestMessage,
                    type: 'warning',
                });
            } else if (response.status === 500) {
                setTouInfo(null);
                setMessage({
                    text: 'An error occurred.',
                    type: 'danger',
                });
            }
            else if (response.status === 403) {
                setMessage({
                    text: 'You do not have authorization to do that.',
                    type: 'danger',
                });
            }


        } finally { setLoading(false); }
    };

    //////////////////////////////

    
    // const handleConfirmRemoveMethod = (methodId) => {
    //     const shouldRemove = window.confirm("Are you sure you want to remove this MFA method?");
    //     if (shouldRemove) {
    //       handleRemoveMethod(methodId);
    //     }
    // };
      
    const handleAddTOUAction = async (touInfo,action) => {
        setLoading(true);
        try {
            console.log("Deleting method with id: " + touInfo.ID);
            // TODO: call the API to delete the method that was selected

            const tokenResponse = await instance.acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
            });

            let response;
            try {
                var touURI = env.API_B2C_USERS_ENDPOINT+"/"+userSearch+"/tou?code=" + env.API_B2C_USERS_CODE;

                response = await fetch(touURI, {
                    method: "PATCH",
                    headers: {
                        Authorization: `Bearer ${tokenResponse.accessToken}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        action: action
                    }),
                });
            } catch (e) {
                console.error(e);
                // stack trace (string): e.stack
                // string message: e.message
                setMessage({ type: 'danger', text: 'Error communicating with server' });
                return;
            }

            if (response.status === 200) {
                console.log("Success");

                // There is probably a better way to do this... but oh well.
                handleClearUPN();

                setMessage({
                    text: "Operation: '" + action + "' was successful.",
                    type: 'warning'
                })
            } else if (response.status === 400) {
                console.log("Bad Request");
                let badRequestMessage = await response.text();
                setMessage({
                    text: badRequestMessage,
                    type: 'warning',
                });
            } else if (response.status === 500) {
                setMessage({
                    text: 'An error occurred.',
                    type: 'danger',
                });
            } else if (response.status === 403) {
                setMessage({
                    text: 'You do not have authorization to do that.',
                    type: 'danger',
                });
            }

            
        } finally { setLoading(false); }
    };

    ///////////////////////////////////////////////////

    const handleClearUPN = () => {
        setTouInfo(null);
        setMessage(null);
        setLocked(false);
        return;
    };

    const handleUserSearchChange = (event) => {
        setUserSearch(event.target.value);
        return (<div />)
    };

    const CardDisplay = (touInfo) => {
        return (
            <div>{
                <Table bordered size='sm' >
                    <tbody>
                        <tr>
                            <th>Managed Username:</th>
                            <th>{touInfo.ManagedUsername}</th>
                        </tr>
                        <tr>
                            <th>User ID:</th>
                            <th>{touInfo.ID}</th>
                        </tr>
                        <tr>
                            <th>TOU Required:</th>
                            <th>{touInfo.TOURequired ? "Yes" : "No"}</th>
                        </tr>
                        <tr>
                            <th>TOU Accepted:</th>
                            <th>{touInfo.TOUAccepted ? "Yes" : "No"}</th>
                        </tr>
                        <tr>
                            <th>TOU Accepted Version:</th>
                            <th>{touInfo.TOUAccepted ? touInfo.TOUAcceptedVersion : "N/A"}</th>
                        </tr>
                        <tr>
                            <th>TOU Accepted Date:</th>
                            <th>{touInfo.TOUAccepted ? touInfo.TOUAcceptedDate : "N/A"}</th>
                        </tr>
                        
                    </tbody>
                </Table>
            }</div>
        )
    };

    return (
        <div>
            <div className='touFormHeader'>
                B2C User TOU Lookup
            </div>
            <div className='B2CUserTOUForm'>
                <Form onSubmit={handleSubmit}>
                    <FormGroup>
                        {/* <h5>User Principal Name:</h5> */}
                        <Label for="userSearch">Managed Username or Object ID:</Label>
                        <Input
                            type="text"
                            id="userSearch"
                            value={userSearch}
                            onChange={handleUserSearchChange}
                            disabled={loading || locked}
                        />
                    </FormGroup>
                    <Button disabled={loading || locked} type="submit" className='button' style={{ width: '132px', height: '38px', margin: '2px' }}>
                        {loading ? <Spinner animation='border' size='sm' /> : 'Get TOU Info'}
                    </Button>
                    <Button className='button' style={{ width: "auto", height: '38px', margin: '2px' }} onClick={handleClearUPN}>
                        Clear Search
                    </Button>
                </Form>
                {touInfo && (
                    <div className="mt-3" style={{ marginTop: '100' }}>
                        <Label>TOU Info: {touInfo.length}</Label>
                        {CardDisplay(touInfo)}
                        {canAddTOURequirement && (
                            <Button className='button' style={{ width: "auto", height: '38px', margin: '2px' }} onClick={() => handleAddTOUAction(touInfo,"addRequirement")}>
                                Require TOU
                            </Button>
                        )}
                        {canRemoveTOURequirement && (
                            <Button className='button' style={{ width: "auto", height: '38px', margin: '2px' }} onClick={() => handleAddTOUAction(touInfo,"removeRequirement")}>
                                Remove TOU Requirement
                            </Button>
                        )}
                        {canResetTOUStatus && (
                            <Button className='button' style={{ width: "auto", height: '38px', margin: '2px' }} onClick={() => handleAddTOUAction(touInfo,"addRequirement")}>
                                Reset TOU
                            </Button>
                        )}
                        {/* {canRemoveTOURequirement ? (<div>we can add the requirement</div>)}
                        {canResetTOUStatus ? (<div>we can reset requirement</div>)} */}
                    </div>
                )}
                {message && (
                    <div className="mt-3">
                        <Alert color={message.type}>{message.text}</Alert>
                    </div>
                )}
            </div>
        </div>
    );
};
