import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaArrowUp, FaArrowDown } from 'react-icons/fa';

function ADLockoutDataTable({ data }) {
    const [sortConfig, setSortConfig] = useState({
        key: 'max_timestamp',
        direction: 'descending'
    });

    const handleSort = (columnName) => {
        let direction = 'ascending';
        if (sortConfig && sortConfig.key === columnName && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key: columnName, direction: direction });
    };

    const sortedData = () => {
        if (sortConfig !== null) {
            return data.sort((a, b) => {
                const keyA = a[sortConfig.key];
                const keyB = b[sortConfig.key];
                if (keyA < keyB) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (keyA > keyB) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return data;
    };

    return (
        <Table striped bordered hover>
            <thead>
                <tr style={{fontWeight:'normal'}}>
                    <th onClick={() => handleSort('user.target.domain')} style={{fontWeight:'500'}}>
                        Computer {sortConfig.key === 'user.target.domain' && (sortConfig.direction === 'ascending' ? <FaArrowUp /> : <FaArrowDown />)}
                    </th>
                    <th onClick={() => handleSort('max_timestamp')} style={{fontWeight:'500'}}>
                        Last Lockout {sortConfig.key === 'max_timestamp' && (sortConfig.direction === 'ascending' ? <FaArrowUp /> : <FaArrowDown />)}
                    </th>
                </tr>
            </thead>
            <tbody>
                {sortedData().map((item, index) => (
                    <tr key={index}>
                        <td>{item['user.target.domain']}</td>
                        <td>{item.max_timestamp}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
}

export default ADLockoutDataTable;
