import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaArrowUp, FaArrowDown } from 'react-icons/fa';

function SignInActivityTable({ data }) {
    const [sortConfig, setSortConfig] = useState({
        key: 'max_timestamp',
        direction: 'descending'
    });

    const handleSort = (columnName) => {
        let direction = 'ascending';
        if (sortConfig && sortConfig.key === columnName && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key: columnName, direction: direction });
    };

    const sortedData = () => {
        if (sortConfig !== null) {
            return data.sort((a, b) => {
                const keyA = a[sortConfig.key];
                const keyB = b[sortConfig.key];
                if (keyA < keyB) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (keyA > keyB) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return data;
    };

    return (
        <Table striped bordered hover>
            <thead>
                <tr style={{fontWeight:'normal'}}>
                    <th onClick={() => handleSort('azure.signinlogs.properties.app_display_name')} style={{fontWeight:'500'}}>
                        App {sortConfig.key === 'azure.signinlogs.properties.app_display_name' && (sortConfig.direction === 'ascending' ? <FaArrowUp /> : <FaArrowDown />)}
                    </th>
                    <th onClick={() => handleSort('azure.signinlogs.properties.device_detail.display_name')} style={{fontWeight:'500'}}>
                        Device {sortConfig.key === 'azure.signinlogs.properties.device_detail.display_name' && (sortConfig.direction === 'ascending' ? <FaArrowUp /> : <FaArrowDown />)}
                    </th>
                    <th onClick={() => handleSort('azure.signinlogs.properties.device_detail.trust_type')} style={{fontWeight:'500'}}>
                        Trust Type {sortConfig.key === 'azure.signinlogs.properties.device_detail.trust_type' && (sortConfig.direction === 'ascending' ? <FaArrowUp /> : <FaArrowDown />)}
                    </th>
                    <th onClick={() => handleSort('max_timestamp')} style={{fontWeight:'500'}}>
                        Last Sign-In Event {sortConfig.key === 'max_timestamp' && (sortConfig.direction === 'ascending' ? <FaArrowUp /> : <FaArrowDown />)}
                    </th>
                </tr>
            </thead>
            <tbody>
                {sortedData().map((item, index) => (
                    <tr key={index}>
                        <td>{item['azure.signinlogs.properties.app_display_name']}</td>
                        <td>{
                            item['azure.signinlogs.properties.device_detail.device_id'] !== "" ? (
                            <a href={
                                    "https://portal.azure.com/#view/Microsoft_AAD_Devices/DeviceDetailsBlade/deviceId/"+item['azure.signinlogs.properties.device_detail.device_id']
                                } target="_blank" rel="noopener noreferrer">
                                {item['azure.signinlogs.properties.device_detail.display_name']}
                            </a>
                            ) : (
                                item['azure.signinlogs.properties.device_detail.display_name']
                            )}
                        </td>
                        <td>{item['azure.signinlogs.properties.device_detail.trust_type']}</td>
                        <td>{item.max_timestamp}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
}

export default SignInActivityTable;
