//@ts-check
import '../../styles/SignInActivityForm.css';

import React, { useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../auth/authConfig';
import { Form, FormGroup, Label, Input, Button, Alert, InputGroup } from "reactstrap";
import { Spinner} from "react-bootstrap"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import ecolabLogo from "../../images/Ecolab_Logo_Blue_RGB.png"
import env from 'react-dotenv';
import SignInActivityTable from "../DataTables/SignInActivityDataTable"

export const SignInActivityForm = () => {
    const [userPrincipalName, setUserPrincipalName] = useState("");
    const [signInData, setSignInData] = useState("");
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(/** @type {null | {text: string, type: string}} **/(null));
    const { instance, accounts } = useMsal();

    const handleSubmit = async (event) => {
        setLoading(true);
        try {
            event.preventDefault();

            const tokenResponse = await instance.acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
            });

            let response;
            try {
                response = await fetch(env.API_SIGNIN_IN_ACTIVITY_ENDPOINT + '?' + new URLSearchParams({
                        code: env.API_SIGNIN_IN_ACTIVITY_ENDPOINT_CODE,
                        userPrincipalName: userPrincipalName,
                        signInType: "interactive",
                        daysToSearch: "14"
                    }), {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${tokenResponse.accessToken}`,
                        "Content-Type": "application/json",
                    }
                });
            } catch (e) {
                console.error(e);
                // stack trace (string): e.stack
                // string message: e.message
                setMessage({ type: 'danger', text: 'Error communicating with server' });
                return;
            }

            if (response.status === 200) {
                console.log("Success");

                let jsonString = await response.text();
                let responseObject = JSON.parse(jsonString);

                console.log(responseObject);

                if(responseObject.azureUserFound != true){
                    setSignInData('');
                    setMessage({
                        text: 'User Not Found',
                        type: 'warning',
                    });
                } else {
                    if(responseObject.signInActivityFound == false){
                        setSignInData('');
                        setMessage({
                            text: 'No Sign-In Data Found',
                            type: 'warning',
                        });
                    } else {
                        setSignInData(responseObject.signInActivity);
                        setMessage(null);
                    }
                }
                
            } else if (response.status === 400) {
                console.log("Bad Request");
                let badRequestMessage = await response.text();
                setSignInData('');
                setMessage({
                    text: badRequestMessage,
                    type: 'warning',
                });
            } else if (response.status === 500) {
                setSignInData('');
                setMessage({
                    text: 'An error occurred.',
                    type: 'danger',
                });
            } else if (response.status === 403) {
                setMessage({
                    text: 'You do not have authorization to do that.',
                    type: 'danger',
                });
            }


        } finally { setLoading(false); }
    };

    const handleUserPrincipalNameChange = (event) => {
        setUserPrincipalName(event.target.value);
    };

    return (
        <div>
            <h3 style={{marginLeft: '1em', marginTop: '.25em', color: 'darkslategray' }}>
            Sign-In Activity Lookup
            </h3>
            <div className='SignInActivityForm'>
                <Form onSubmit={handleSubmit}>
                    <FormGroup>
                        {/* <img
                            src={ecolabLogo}
                            alt="logos"
                            height={35}
                            width={200}
                        />
                        <br></br>
                        <br></br> */}
                        <Label for="userPrincipalName">User Principal Name</Label>
                        <InputGroup >
                            <Button disabled={loading} type="submit" className='button' style={{width: "75px"}}>
                                {loading ? <Spinner animation='border' size='sm' /> : <FontAwesomeIcon icon={faSearch} />}
                            </Button>
                            <Input
                                type="text"
                                id="userPrincipalName"
                                value={userPrincipalName}
                                onChange={handleUserPrincipalNameChange}
                                disabled={loading}
                            />   
                        </InputGroup>
                        
                    </FormGroup>
                </Form>
                {signInData && (
                    <div className="mt-4">
                        {/* right here we need to show the azureUser.displayName, azureUser.userPrincipalName, and links to the user in Azure with the azureUser.id  */}
                        <Label>Sign-In Activity (interactive only, last 14 days)</Label>
                        <SignInActivityTable data={signInData}/>
                    </div>
                )}
                {message && (
                    <div className="mt-4">
                        <Alert color={message.type}>{message.text}</Alert>
                    </div>
                )}
            </div>
        </div>
    );
};
