//@ts-check
import '../../styles/MFADetailForm.css';

import React, { useState } from 'react';
import env from 'react-dotenv';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../auth/authConfig';
import { FormGroup, Label, Input, Alert } from "reactstrap";
// import Card from 'react-bootstrap/Card'
import { Card, Spinner, Button, Dropdown, Modal, Form, Row, Col } from "react-bootstrap"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import ecolabLogo from "../../images/Ecolab_Logo_Blue_RGB.png"

function jwtDecode(token) {
    const [header, payload, signature] = token.split(".");
    const decodedPayload = JSON.parse(new TextDecoder().decode(Uint8Array.from(atob(payload), c => c.charCodeAt(0))));
    return decodedPayload;
}

export const MFADetailForm = () => {
    const [userPrincipalName, setUserPrincipalName] = useState("");
    const [methods, setMethods] = useState(/** @type {null | Array} **/(null));
    const [loading, setLoading] = useState(false);
    const [locked, setLocked] = useState(false);
    const [canResetMFA, setCanResetMFA] = useState(false);
    const [message, setMessage] = useState(/** @type {null | {text: string, type: string}} **/(null));
    const {instance, accounts} = useMsal();
    const [isProtectedUser, setIsProtectedUser] = useState(false);
    const [showAddPhoneModal, setShowAddPhoneModal] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [phoneType, setPhoneType] = useState("");
    const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);

    const handleSubmit = async (event) => {
        setLoading(true);
        try {
            if (event) event.preventDefault();
            console.log(`Getting MFA methods for user: ${userPrincipalName}`);

            const tokenResponse = await instance.acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
            });

            const decodedAccessToken = jwtDecode(tokenResponse.accessToken);

            // TODO: make this a list of multiple acceptable roles
            if (decodedAccessToken.roles && (decodedAccessToken.roles.indexOf('MFA.ReadWrite') > -1)||(decodedAccessToken.roles.indexOf('MFA.ReadWrite.All') > -1)) {
                setCanResetMFA(true);
            } else {
                setCanResetMFA(false);
            }

            let response;
            try {
                response = await fetch(env.API_USERS_ENDPOINT + "/" + userPrincipalName + "/mfaMethods", {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${tokenResponse.accessToken}`
                    }
                });
            } catch (e) {
                console.error(e);
                // stack trace (string): e.stack
                // string message: e.message
                setMessage({ type: 'danger', text: 'Error communicating with server' });
                return;
            }

            if (response.status === 200) {
                let jsonString = await response.text();
                let jsonObject = await JSON.parse(jsonString);
                let methodSummary = jsonObject.summary;

                setMethods(methodSummary);
                setLocked(true);
                setMessage(null);

                if (jsonObject.isProtectedUser && canResetMFA) {
                    setMessage({ type: 'light', text: "Note: This user's authentication methods are protected and cannot be modified." });
                    setIsProtectedUser(true);
                } else {
                    console.log("no protected user message needed")
                    setIsProtectedUser(false);
                }

            } else if (response.status === 400) {
                console.log("Bad Request");
                let badRequestMessage = await response.text();
                setMethods(null);
                setMessage({
                    text: badRequestMessage,
                    type: 'warning',
                });
            } else if (response.status === 500) {
                setMethods(null);
                setMessage({
                    text: 'An error occurred.',
                    type: 'danger',
                });
            }
            else if (response.status === 403) {
                setMessage({
                    text: 'You do not have authorization to do that.',
                    type: 'danger',
                });
            }


        } finally { setLoading(false); }
    };

    const handleConfirmRemoveMethod = (methodId) => {
        const shouldRemove = window.confirm("Are you sure you want to remove this MFA method?");
        if (shouldRemove) {
            handleRemoveMethod(methodId);
        }
    };

    const handleRemoveMethod = async (methodId) => {
        setLoading(true);
        try {
            console.log("Deleting method with id: " + methodId);
            // TODO: call the API to delete the method that was selected

            const tokenResponse = await instance.acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
            });

            let response;
            try {
                response = await fetch(env.API_USERS_ENDPOINT + "/" + userPrincipalName + "/mfaMethods/" + methodId, {
                    method: "DELETE",
                    headers: {
                        Authorization: `Bearer ${tokenResponse.accessToken}`
                    }
                });
            } catch (e) {
                console.error(e);
                // stack trace (string): e.stack
                // string message: e.message
                setMessage({ type: 'danger', text: 'Error communicating with server' });
                return;
            }

            if (response.status === 200) {
                console.log("Success");
                if (methods) {
                    var remaining = methods.filter((method) => method.id !== methodId);

                    setMethods(remaining);
                }
            } else if (response.status === 400) {
                console.log("Bad Request");
                let badRequestMessage = await response.text();
                setMessage({
                    text: badRequestMessage,
                    type: 'warning',
                });
            } else if (response.status === 500) {
                setMessage({
                    text: 'An error occurred.',
                    type: 'danger',
                });
            } else if (response.status === 403) {
                setMessage({
                    text: 'You do not have authorization to do that.',
                    type: 'danger',
                });
            }

        } finally { setLoading(false); }
    };

    const handleClearUPN = () => {
        setMethods(null);
        setMessage(null);
        setLocked(false);
        return;
    };

    const handleUserPrincipalNameChange = (event) => {
        setUserPrincipalName(event.target.value);
        return (<div />)
    };

    const CardDisplay = (methods) => {
        return (<div style={{ marginTop: '1em' }}>
            {methods.map((item) => (
                <Card className='mfaMethodCard' key={item.id} style={{ margin: '0em', padding: '.75em' }}>
                    <Card.Title>
                        {item.methodType}
                        {!isProtectedUser && canResetMFA && (<FontAwesomeIcon icon={faTrash} onClick={() => handleConfirmRemoveMethod(item.id)} className="trash-icon" style={{ float: 'right' }} />)}
                        <p style={{ fontSize: '12px', paddingRight: '10px', float: 'right' }}>{item.createdDateTime && (item.createdDateTime)}</p>
                    </Card.Title>

                    <Card.Body style={{ margin: '-10px' }}>
                        {Object.entries(item.details).map(([key, value]) => `${key}: ${value}`).map((item) => (<div> {item} </div>))}
                    </Card.Body>
                </Card>
            ))}
        </div>)
    };

    const handleShowAddPhoneModal = (type) => {
        setPhoneType(type);
        setShowAddPhoneModal(true);
        return;
    }

    const handleCloseAddPhoneModal = () => {
        setPhoneNumber("");
        setIsValidPhoneNumber(false);
        setShowAddPhoneModal(false);
        return;
    }

    const handlePhoneNumberChange = (event) => {
        setPhoneNumber(event.target.value);

        const regex = /^\+\d{1,3} \d{7,14}(x\d+)?$/;

        if (regex.test(event.target.value)) {
            setIsValidPhoneNumber(true);
        } else {
            setIsValidPhoneNumber(false)
        }

        return
    };

    const handleSubmitAddPhoneNumberMethod = async () => {
        setLoading(true);
        handleCloseAddPhoneModal();
        try {
            // TODO: call the API to delete the method that was selected

            const tokenResponse = await instance.acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
            });

            let response;
            try {
                response = await fetch(env.API_USERS_ENDPOINT + "/" + userPrincipalName + "/mfaMethods", {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${tokenResponse.accessToken}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        MethodType: "phoneNumber",
                        PhoneNumber: phoneNumber,
                        PhoneType: phoneType
                    })
                });
            } catch (e) {
                console.error(e);
                // stack trace (string): e.stack
                // string message: e.message
                setMessage({ type: 'danger', text: 'Error communicating with server' });
                return;
            }

            var addedMethodSuccessfully = false

            if (response.status === 200) {
                addedMethodSuccessfully = true
                console.log("Success");
            } else if (response.status === 400) {
                console.log("Bad Request");
                let badRequestMessage = await response.text();
                setMessage({
                    text: badRequestMessage,
                    type: 'warning',
                });
            } else if (response.status === 500) {
                setMessage({
                    text: 'An error occurred.',
                    type: 'danger',
                });
            } else if (response.status === 403) {
                setMessage({
                    text: 'You do not have authorization to do that.',
                    type: 'danger',
                });
            }
        } finally {
            setLoading(false);
        }

        if(addedMethodSuccessfully){
            handleSubmit();
        }
    };

    const AddMethodDropdown = () => {

        return (
            <div>
                <Dropdown>
                    <Dropdown.Toggle size='sm' id="dropdown-basic">
                        Add Method
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => handleShowAddPhoneModal("mobile")}>Phone - Mobile</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleShowAddPhoneModal("alternateMobile")}>Phone - Alternate Mobile</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleShowAddPhoneModal("office")}>Phone - Office</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                <Modal centered show={showAddPhoneModal} onHide={handleCloseAddPhoneModal} >
                    <Modal.Header closeButton>
                        <Modal.Title>Add Phone Method</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            {/* TODO: auto-focus on this text box */}
                            {/* TODO: submit on enter */}
                            <Form.Label for="phoneNumber">Phone Number - {phoneType}</Form.Label>
                            <Form.Control
                                type="text"
                                id="phoneNumber"
                                value={phoneNumber}
                                onChange={handlePhoneNumberChange}
                                disabled={loading}
                            />
                            <Form.Text>Phone numbers must use the format: +1 5555551234</Form.Text>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleSubmitAddPhoneNumberMethod} disabled={!isValidPhoneNumber}>
                            Submit
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    };

    return (
        <div>
            <div className='formHeader'>
                MFA Lookup
            </div>
            <div className='MFADetailForm'>
                <Form onSubmit={handleSubmit}>
                    <FormGroup>
                        <Label for="userPrincipalName">User Principal Name</Label>
                        <Input
                            type="text"
                            id="userPrincipalName"
                            value={userPrincipalName}
                            onChange={handleUserPrincipalNameChange}
                            disabled={loading || locked}
                        />
                    </FormGroup>
                    <Button disabled={loading || locked} type="submit" className='button' style={{ width: '162px', height: '38px', margin: '2px' }}>
                        {loading ? <Spinner animation='border' size='sm' /> : 'Get MFA Methods'}
                    </Button>
                    <Button className='button' style={{ width: 'auto', height: '38px', margin: '2px' }} onClick={handleClearUPN}>
                        Clear Search
                    </Button>
                </Form>
                {message && (
                    <div className="mt-3">
                        <Alert color={message.type}>{message.text}</Alert>
                    </div>
                )}
                {methods && (
                    <div>
                        <div className="mt-3" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Label style={{ margin: '0' }}>MFA Methods: {methods.length}</Label>
                            {!isProtectedUser  && canResetMFA && (AddMethodDropdown())}
                        </div>
                        <div>{CardDisplay(methods)}</div>
                    </div>
                )}
               
            </div>
        </div>
    );
};
