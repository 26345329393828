//@ts-check
import '../../styles/TapForm.css';
// import './styles/TapForm.mobile.css'; // Include the mobile styles

import React, { useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../auth/authConfig';
import { Form, FormGroup, Label, Input, Button, Alert } from "reactstrap";
import { Spinner, ModalHeader} from "react-bootstrap"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

import ecolabLogo from "../../images/Ecolab_Logo_Blue_RGB.png"
import env from 'react-dotenv';

export const TAPForm = () => {
    const [userPrincipalName, setUserPrincipalName] = useState("");
    const [tap, setTap] = useState("");
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(/** @type {null | {text: string, type: string}} **/(null));
    const { instance, accounts } = useMsal();

    const handleSubmit = async (event) => {
        setLoading(true);
        try {
            event.preventDefault();
            console.log(`Generating TAP for user: ${userPrincipalName}`);

            const tokenResponse = await instance.acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
            });

            let response;
            try {
                response = await fetch(env.API_TAP_ENDPOINT, {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${tokenResponse.accessToken}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        UserPrincipalName: userPrincipalName,
                    }),
                });
            } catch (e) {
                console.error(e);
                // stack trace (string): e.stack
                // string message: e.message
                setMessage({ type: 'danger', text: 'Error communicating with server' });
                return;
            }

            if (response.status === 200) {
                console.log("Success");
                let responseText = await response.text();
                setTap(responseText);
                setMessage(null);
            } else if (response.status === 400) {
                console.log("Bad Request");
                let badRequestMessage = await response.text();
                setTap('');
                setMessage({
                    text: badRequestMessage,
                    type: 'warning',
                });
            } else if (response.status === 500) {
                setTap('');
                setMessage({
                    text: 'An error occurred. Is TAP enabled for this user?',
                    type: 'danger',
                });
            } else if (response.status === 403) {
                setMessage({
                    text: 'You do not have authorization to do that.',
                    type: 'danger',
                });
            }


        } finally { setLoading(false); }
    };

    const handleCopyToClipboard = () => {
        if (tap) {
          navigator.clipboard.writeText(tap)
            .then(() => {
              alert('Temporary Access Pass copied to clipboard!');
            })
            .catch((err) => {
              console.error('Failed to copy Temporary Access Pass: ', err);
            });
        }
      };
      

    const handleUserPrincipalNameChange = (event) => {
        setUserPrincipalName(event.target.value);
    };

    return (
        <div>
            <h3 style={{ marginTop: "2em", marginLeft: 'auto', marginRight: 'auto', width: '700px', color: 'darkslategray' }}>
                Temporary Access Pass (TAP) Generator
            </h3>
            <div className='TapForm'>
                <Form onSubmit={handleSubmit}>
                    <FormGroup>
                        <img
                            src={ecolabLogo}
                            alt="logos"
                            height={35}
                            width={200}
                        />
                        <br></br>
                        <br></br>

                        <Label for="userPrincipalName">User Principal Name (i.e. Email)</Label>
                        <Input
                            type="text"
                            id="userPrincipalName"
                            value={userPrincipalName}
                            onChange={handleUserPrincipalNameChange}
                            disabled={loading}
                        />
                    </FormGroup>
                    <Button disabled={loading} type="submit" className='button' style={{ width: '130px', height: '38px', margin: "2px" }}>
                        {/* {loading ? 'Generating...' : 'Generate TAP'} */}
                        {loading ? <Spinner animation='border' size='sm' /> : 'Generate TAP'}
                    </Button>
                </Form>
                {tap && (
                    <div className="mt-3">
                        <Label>Temporary Access Pass:</Label>
                        <Alert>{tap}
                            <Button
                                className="copy-icon"
                                onClick={handleCopyToClipboard}
                                style={{ width: '30px', height: '30px', padding: 0 ,float: 'right'}}
                                >
                                <FontAwesomeIcon icon={faCopy} />
                            </Button>
                        </Alert>
                    </div>
                )}
                {message && (
                    <div className="mt-3">
                        <Alert color={message.type}>{message.text}</Alert>
                    </div>
                )}
            </div>
        </div>
    );
};
