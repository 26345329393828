//@ts-check

import React from 'react';
import './styles/App.css';
import { PageLayout } from './components/Layout/PageLayout';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { TAPForm } from './components/Forms/TAPForm';
import { MFADetailForm } from './components/Forms/MFADetailForm';
import { B2CUserTOUForm } from './components/Forms/B2CUserTOUForm'; 
import { SignInActivityForm } from './components/Forms/SignInActivityForm';
import { Route, Routes, Navigate } from 'react-router';
import env from 'react-dotenv';
import { ADLockoutForm } from './components/Forms/ADLockoutForm';


const MainContent = () => {
    return (
        <div className="App">
            <AuthenticatedTemplate>
                <Routes>
                    <Route path="/tap" element={<TAPForm />}/>
                    <Route path="/mfa" element={<MFADetailForm />}/>
                    <Route path="/tou" element={<B2CUserTOUForm />}/>
                    {/* <Route index element={ <Navigate to="/tap" /> } /> */}
                    <Route path="/signInActivity" element={<SignInActivityForm />}/>
                    <Route path="/adLockout" element={<ADLockoutForm />}/>
                    <Route index element={ env.WORDS } />
                </Routes>
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <h5 className="card-title">Please sign-in.</h5>
            </UnauthenticatedTemplate>
        </div>
    );
};

export default function App() {
    return (
        <PageLayout>
            <MainContent />
        </PageLayout>
    );
}
