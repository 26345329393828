import React, { useState} from "react";
import { NavDropdown, Image } from 'react-bootstrap';
import { useAuth } from "../../auth/auth";
import dropdownImage from '../../images/hamburger.png'
import { Link } from 'react-router-dom';
import { useIsAuthenticated } from "@azure/msal-react";

const HamburgerMenuImage = (
    <span>
        <Image src={dropdownImage}
            style={{ width: '23px', paddingBottom: '1px'}}
        />
    </span>
)

export const HamburgerMenu = () => {
    const { getRoles } = useAuth();
    const [showTapForm, setShowTapForm] = useState(false);
    const [showMfaForm, setShowMfaForm] = useState(false);
    const [showTouForm, setShowTouForm] = useState(false);
    const [showSignInActivityForm, setShowSignInActivityForm] = useState(false);
    const [showAdLockoutForm, setShowAdLockoutForm] = useState(false);

    //check if user is authenticated
    const isAuthenticated = useIsAuthenticated();

    const enableFormsBasedOnRoles = async () => {
        //default to no roles
        let tokenRoles = [];

        //populate tokenRoles
        if(isAuthenticated){
            tokenRoles = await getRoles();
        }

        //show forms based on available roles
        if(tokenRoles.indexOf('MFA.Read') > -1 || tokenRoles.indexOf('MFA.Reset') > -1 || tokenRoles.indexOf('MFA.ReadWrite') > -1 || tokenRoles.indexOf('MFA.Read.All') > -1 || tokenRoles.indexOf('MFA.ReadWrite.All') > -1){
            setShowMfaForm(true);
        }

        if(tokenRoles.indexOf('MFA.TAP.CREATE') > -1){
            setShowTapForm(true);
        }
        
        if(tokenRoles.indexOf('KibanaLogs.Read') > -1){
            setShowAdLockoutForm(true);
            setShowSignInActivityForm(true);
        }

        if(tokenRoles.indexOf('B2C.Read') > -1 || tokenRoles.indexOf('B2C.ReadWrite') > -1 || tokenRoles.indexOf('B2C.TOU.Reset') > -1){
            setShowTouForm(true);
        }
    }

    enableFormsBasedOnRoles();

    return (
        <NavDropdown title={HamburgerMenuImage} id="basic-nav-dropdown" >
            {showTapForm && <NavDropdown.Item as={Link} to="/tap">Temporary Access Pass</NavDropdown.Item>}
            {showMfaForm && <NavDropdown.Item as={Link} to="/mfa">MFA Method Detail</NavDropdown.Item>}
            {showSignInActivityForm && <NavDropdown.Item as={Link} to="/signInActivity">Sign-In Activity</NavDropdown.Item>}
            {showAdLockoutForm && <NavDropdown.Item as={Link} to="/adLockout">AD Lockouts</NavDropdown.Item>}
            {showTouForm && <NavDropdown.Item as={Link} to="/tou">B2C TOU</NavDropdown.Item>}
        </NavDropdown>
    )
}
