import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";

function jwtDecode(token) {
    const [header, payload, signature] = token.split(".");
    const decodedPayload = JSON.parse(new TextDecoder().decode(Uint8Array.from(atob(payload), c => c.charCodeAt(0))));
    return decodedPayload;
}

export const useAuth = () => {
    const { instance, accounts } = useMsal();

    const initiateLogin = async () => {
        try {
            const loginResponse = await instance.loginRedirect(loginRequest);
            return loginResponse.account;
        } catch (error) {
            console.error("Error during login:", error);
            throw error;
        }
    };

    const getToken = async () => {
        try {
            const response = await instance.acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
            });
            return response.accessToken;
        } catch (error) {
            console.error("Error acquiring access token:", error);
            throw error;
        }
    };

    const getRoles = async () => {
        try {
            const response = await instance.acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
            });

            const parsedToken = jwtDecode(response.accessToken);

            return parsedToken.roles;
        } catch (error) {
            console.error("Error acquiring access token:", error);
            throw error;
        }
    };

    return { initiateLogin, getToken, getRoles };
};