import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import env from "react-dotenv";
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/index.css';
// import App from './App';
import App from './App';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig, loginRequest } from './auth/authConfig';
import { BrowserRouter, HashRouter } from 'react-router-dom';

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));

const accounts = msalInstance.getAllAccounts();

if (accounts.length > 0) {
  
} else {
    msalInstance.handleRedirectPromise().then(authResult=>{
        if (authResult) {
          // Process authResult if necessary (e.g., store tokens, set active account, etc.)
          msalInstance.setActiveAccount(authResult.account);
        }
        
        // check if an account exists
        const account = msalInstance.getActiveAccount();
        if(!account){
          // sign in if not
          msalInstance.loginRedirect(loginRequest);
        }
      }).catch(err=>{
        console.log(err);
      });
}

// handle auth redired/do all initial setup for msal

/**
 * We recommend wrapping most or all of your components in the MsalProvider component. It's best to render the MsalProvider as close to the root as possible.
 */
root.render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>
            <HashRouter>
                <App />
            </HashRouter>
        </MsalProvider>
    </React.StrictMode>
);
